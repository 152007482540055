import React, { useEffect, useState } from "react";
import Header from "../../Header";
import axios from "axios";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import { logError, logInfo } from "../../../Elasticsearchinstance";
//import { logError, logInfo } from "../../../Elasticsearchinstance";

// const getInstructionURL ="https://examserver.elite-professionals.in/getinstruction";
const candidatedetailsURL = "https://examserver.elite-professionals.in/candidatepost";

const postCandidateExamStatusURL = "https://examserver.elite-professionals.in/postCandidateExamStatus";

const sendEmailURL = "https://www.emailapi.elite-professionals.in/api/sendmail";

const StudentTakeExam = () => {
  // get data Instruction
  //   const [getinstruction, setGetInstruction] = useState([]);
  //   useEffect(() => {
  //     const fetchData = async () => {
  //       let data = await fetch(getInstructionURL);
  //       let jsonData = await data.json();
  //       // console.log(jsonData);
  //       setGetInstruction(jsonData.result);
  //     };
  //     fetchData();
  //   }, []);

  const [randomFreelancerID, setRandomFreelancerID] = useState("");
  const [randomName, setRandomName] = useState("");
  const [randomAddress, setRandomAddress] = useState("");
  const [randomPhoneNumber, setRandomPhoneNumber] = useState("");
  const [randomJobProfile, setRandomJobProfile] = useState("");
  const [randomTechSkills, setRandomTechSkills] = useState("");
  //   const [randomGroupTag, setRandomGroupTag] = useState("");
  const [randomStatus, setRandomStatus] = useState("");
  const [randomEnrollmentNumber, setRandomEnrollmentNumber] = useState("");
  //   const [randomExperience, setRandomExperience] = useState("");
  const [randomSalaryExpectation, setRandomSalaryExpectation] = useState("");
  const [randomEducation, setRandomEducation] = useState("");

  // dynamic data ke lie
  const [data, setData] = useState({
    freelancerID: "",
    name: "",
    email: "",
    address: "",
    phone_number: "",
    job_profile: "",
    tech_skills: "",
    group_tag: "",
    status: "",
    enrollment_number: "",
    experience: "",
    salary_expectation: "",
    education: "",
  });
  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };
  const RandomStringGenerator = () => {
    const [randomString, setRandomString] = useState("");
    useEffect(() => {
      const randomStr = generateRandomString(10); // Adjust the length as needed
      setRandomString(randomStr);
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  };
  // const fixedEmail = "ngtmail101@gmail.com";
  const randomText = generateRandomString(5);
  const joinedString = randomText;
  const freelancerid = joinedString;

  const name = ["John", "Jane", "Bob", "Alice", "Sam"];
  const address = ["Delhi", "Noida", "Bihar", "Haryana", "Gurgao"];
  const phone_number = [
    "6754321567",
    "7654987865",
    "5645768989",
    "2345436579",
    "6754329876",
  ];
  const job_profile = ["Node", "React", "Java", "DotNet", "PHP"];
  const tech_skills = ["Html", "Css", "JavaScript", "SpringBoot", "JQuery"];
  //   const group_tag = ["John", "Jane", "Bob", "Alice", "Sam"];
  const status = ["Active", "Active", "Active", "Active", "Active"];
  const enrollment_number = ["45645", "87677", "98767", "44325", "76895"];
  //   const experience = ["John", "Jane", "Bob", "Alice", "Sam"];
  const salary_expectation = ["1K", "2K", "3K", "4K", "5K"];
  const education = ["BCA", "MCA", "BTECH", "MTECH", "BCOM"];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * name.length,
      freelancerid,
      address.length,
      phone_number.length,
      job_profile.length,
      tech_skills.length,
      //   group_tag.length,
      status.length,
      enrollment_number.length,
      //   experience.length,
      salary_expectation.length,
      education.length
    );

    const randomFreelancerID = freelancerid;
    setRandomFreelancerID(randomFreelancerID);

    const randomName = name[randomIndex];
    setRandomName(randomName);

    const randomAddress = address[randomIndex];
    setRandomAddress(randomAddress);

    const randomPhoneNumber = phone_number[randomIndex];
    setRandomPhoneNumber(randomPhoneNumber);

    const randomJobProfile = job_profile[randomIndex];
    setRandomJobProfile(randomJobProfile);

    const randomTechSkills = tech_skills[randomIndex];
    setRandomTechSkills(randomTechSkills);

    // const randomGroupTag = group_tag[randomIndex];
    // setRandomGroupTag(randomGroupTag);

    const randomStatus = status[randomIndex];
    setRandomStatus(randomStatus);

    const randomEnrollmentNumber = enrollment_number[randomIndex];
    setRandomEnrollmentNumber(randomEnrollmentNumber);

    // const randomExperience = experience[randomIndex];
    // setRandomExperience(randomExperience);

    const randomSalaryExpectation = salary_expectation[randomIndex];
    setRandomSalaryExpectation(randomSalaryExpectation);

    const randomEducation = education[randomIndex];
    setRandomEducation(randomEducation);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const candi_data = {
        freelancerID: randomFreelancerID,
        name: randomName,
        email: data.email,
        address: randomAddress,
        phone_number: randomPhoneNumber,
        job_profile: randomJobProfile,
        tech_skills: randomTechSkills,
        group_tag: data.group_tag,
        status: randomStatus,
        enrollment_number: randomEnrollmentNumber,
        experience: data.experience,
        salary_expectation: randomSalaryExpectation,
        education: randomEducation,
      };
  // Log candidate data
  logInfo("Candidate data submitted", candi_data);

      const res = await axios.post(candidatedetailsURL, candi_data);
      console.log("candi_data", res);

      const mail_data = {
        from_email: candi_data.email,
        to_email: candi_data.email,
        temp: "Exam Registration",
        first_name: candi_data.email,
        last_name: res.data.password,
      };

      const res1 = await axios.post(sendEmailURL, mail_data);
      // Log email data
     await  logInfo("Email sent", res1.data);
      console.log("mail_data", res1);

      const res2 = await axios.post(postCandidateExamStatusURL, {
        exam_status: "undefine",
        exam_email: candi_data.email,
        exam_course: candi_data.group_tag,
        password: res.data.password,
        freelancerID: candi_data.freelancerID,
      });

    // Log exam status data
    await logInfo("Candidate exam status submitted", res2.data);
      console.log("detail", res2);

      alert("Candidate data inserted");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting candidate data:", error);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast

      // Log error
     logError("Error submitting candidate data", error);
    } finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };

  return (
    <>
      <Header />

      <div className="container">
        <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
          <form onSubmit={formSubmit}>
            <div className="column jumbotron box8">
              <h4 className=" text-info mb-5 texth4">Student Take Exam</h4>

              <div className="form-group row">
                <label htmlFor="emailid" className="col-sm-5 col-form-label">
                  Set Exam Level/Experience
                </label>
                <div className="col-sm-7">
                  <select
                    name="experience"
                    value={data.experience}
                    onChange={inputEvent}
                    className="form-control"
                    //   className="md:w-[19rem] select select-bordered text-black select-sm"
                    required="true"
                  >
                    <option>Select Level/Experience</option>
                    <option value="0-1 yrs">0-1 yrs</option>
                    <option value="1-2 yrs">1-2 yrs</option>
                    <option value="1-3 yrs">1-3 yrs</option>
                    <option value="0-4 yrs">0-4 yrs</option>
                    <option value="1-4 yrs">1-4 yrs</option>
                  </select>
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="userId" className="col-sm-5 col-form-label">
                  Set Exam Course/Group
                </label>
                <div className="col-sm-7">
                  <select
                    name="group_tag"
                    value={data.group_tag}
                    onChange={inputEvent}
                    className="form-control"
                    //   className="select select-bordered text-black select-sm w-full max-w-md "
                    required=""
                  >
                    <option>Select Course/Group</option>
                    <option value="Java Developer">Java Developer</option>
                    <option value="Node Js">Node Js</option>
                    <option value="React Js">React Js</option>
                    <option value="Angular Js">Angular Js</option>
                    <option value="Vue Js">Vue Js</option>
                  </select>
                </div>
              </div>
              {/* <div className="form-group row">
              <label htmlFor="title" className="col-sm-5 col-form-label">
                Set Exam Instruction
              </label>
              <div className="col-sm-7">
                <select
                  name="instruction"
                  className="form-control"
                  //   className="select select-bordered text-black select-sm w-full max-w-md "
                  required
                >
                  <option>Select Instruction</option>
                  {getinstruction &&
                    getinstruction.map((instruction, index) => (
                      <option value={instruction.title}>
                        {instruction.title}
                      </option>
                    ))}
                </select>
              </div>
            </div> */}

              <div className="form-group row">
                <label htmlFor="location" className="col-sm-5 col-form-label">
                  email
                </label>
                <div className="col-sm-7">
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="Enter Your Email"
                    className="form-control"
                    value={data.email}
                    onChange={inputEvent}
                  />
                </div>
              </div>

              {/* <div className="form-group row">
              <label htmlFor="location" className="col-sm-5 col-form-label">
                Location
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  name="location"
                  id="location"
                  className="form-control"
                  placeholder=""
                  //   value={randomJobLocation}
                  //   onChange={(event) => {
                  //     setLocation(event.target.value);
                  //     userJobLocationChange(event);
                  //   }}
                />
              </div>
            </div> */}

              <br />

              <div className=" form-group mb-0">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
              <br />
              <br />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default StudentTakeExam;
