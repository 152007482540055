import React, { useState } from 'react'
import Header from '../../Header'
import toast, { Toaster } from 'react-hot-toast'
import Loader from "react-js-loader";
import "./freejob.css";
import useAxiosPublic from "../../Hooks/useAxiosPublic";

const FreelanceJobApply = () => {
  const axiousPublic = useAxiosPublic();

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");
  const [datas1, changeDatas1] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  

  const getdata = async (e) => {
    e.preventDefault();
    if (!email.trim() || !password.trim()) {
      alert("Please enter both email and password");
      return;
    }
    setLoading(true); // Start loading before the API call
    
  try {
      const responselogin = await axiousPublic.post(`/auth/login`, {
        email,
        password,
      });

      if (responselogin.data.success) {
      console.log("userlogindataID", responselogin.data.data.user.userId);
      const profilefreelancerId = responselogin.data.data.user.userId; //data.freelancerId || "234"; // or get it from somewhere

      const response = await axiousPublic.get(`/jobs/freelancer`,{ 
        headers: {
          id: profilefreelancerId,
        },
       });
      console.log("get all job1", response.data.data.data);

      const jobData = response?.data?.data?.data;
      console.log("jobData",jobData);
      
      if (jobData && jobData.length > 0) {
      console.log("Job data found:", jobData); // Log the job data
      changeDatas1(jobData);
      } else {
        alert("No jobs found.");
        changeDatas1([]); // Clear previous data if no jobs are found
      }
    } 
    else {
      alert("Invalid email or password.");
    }
  }catch (error) {
      console.error("Error fetching data:", error);
      // toast.error("Data not Submitted Try Again !!!"); // Show error toast
      alert("Error fetching data. Please try again.");
    } finally {
      setLoading(false); // Stop loading after the API call
  }
  };


  return (
  <>
     <Header /> 

  {/* <div className="container"> */}
  <div className="container box9">
    <Toaster position="top-center" reverseOrder={false} />
    {loading ? (
      <div className="loader-wrapper">
        <Loader
          type="spinner-circle"
          bgColor={color}
          color={color}
          title={"Processing Your Request"}
          size={100}
        />
      </div>
    ) : (
      <div>
          {/* title */}
          <div className="">
            <h4 className=" gf1">Freelancer Job Apply / Applied All Jobs</h4>
            <br /> 
          </div>

          {/* email/search */}
            <div className="form-group row email1">
                <div className="col-sm-4">
                  <input
                    type="Email"
                    name="email"
                    id="email"
                    placeholder='Type Freelancer Email ID'
                    className="form-control"
                    // onChange={changehandler1}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required="true"
                  />
                </div>
                <div className="col-sm-4">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder='Type Password'
                    className="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required="true"
                  />
                </div>
                <button
                  class="btn btn-primary"
                  type="submit"
                  onClick={getdata}
                  >
                  Search
                </button>
            </div>

          {/* row */}
          {datas1 &&
          datas1.map((ab) => (
          
          <form key={ab.id}>
            <div className="row">
              
              <div className="col-md-10 box1">
                <div className="card mb-3">
                  <div className="row no-gutters align-items-center">

                    <div className="col-md-12">
                      <div className="card-body">
                      <h4 className="card-title">{ab.title}</h4>
                      <p className="card-text">
                          {/* <small className="text-muted">Posted 4 days ago</small> */}
                        </p>
                      <h4 className="card-title">Requrements:</h4>
                        <div className='cards'>
                          <table className='table1'>
                            <div>
                            <tr>
                                <td><h6 htmlFor="">Experience Level: </h6></td>
                                <td><label htmlFor="">{ab.experienceLevel}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">FreelancerType : </h6></td>
                                <td><label htmlFor=""> {ab.freelancerType}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Project Budget : </h6></td>
                                <td><label htmlFor=""> {ab.approxBudget}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">JobDuration : </h6></td>
                                <td><label htmlFor=""> {ab.duration}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Job Type : </h6></td>
                                <td><label htmlFor=""> {ab.jobType}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Start Date : </h6></td>
                                <td><label htmlFor=""> {ab.startDate}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Deadline : </h6></td>
                                <td><label htmlFor=""> {ab.deadline}</label></td>
                              </tr>
                              {/* <tr>
                                <td><h6 htmlFor="">Details : </h6></td>
                                <td><label htmlFor=""> {ab.details}</label></td>
                              </tr> */}
                            </div>
                              
                          {/* </table>
                          <table className='table1'> */}
                          <div>
                          <tr>
                                <td><h6 htmlFor="">Job Categories: </h6></td>
                                <td><label htmlFor="">{ab.categories}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Preferred Experience: </h6></td>
                                <td><label htmlFor=""> {ab.preferredExperience}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">English Level: </h6></td>
                                <td><label htmlFor=""> {ab.languageProficiency}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Location: </h6></td>
                                <td><label htmlFor=""> {ab.location}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Language: </h6></td>
                                <td><label htmlFor=""> {ab.language}</label></td>
                              </tr>
                              <tr>
                                <td><h6 htmlFor="">Expire Date: </h6></td>
                                <td><label htmlFor=""> {ab.expireDate}</label></td>
                              </tr>
                              {/* <tr>
                                <td><h6 htmlFor="">Required skills: </h6></td>
                                <td><label htmlFor=""> {ab.requiredSkills}</label></td>
                              </tr> */}
                          </div>
                              
                          </table>
                        </div>
                        <br />
                        <div className="col-sm-12 form-group mb-0">
                          <button type="button" className="btn btn-primary float-right ml-2">
                            Apply
                          </button>
                          <button type="submit" className="btn btn-primary float-right ml-2">
                            Save
                          </button>
                          {/* <button type="button" className="btn btn-primary float-right ">
                            View More
                          </button> */}
                      </div>
                      <br />
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        
          ))}
          {/* row */}
      </div>
    )}



  </div>
  
  </>
  )
}

export default FreelanceJobApply