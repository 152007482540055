import React, { useEffect, useState } from "react";
import Header from "../../Header";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "../../Hooks/useAxiosPublic";


const ProfilefillClientIndivdual = () => {
  const axiousPublic = useAxiosPublic();

  // const [email_id, emailid] = useState("");
  const [randomAddress, setAddress] = useState("");
  const [randomContact, setContact] = useState("");
  const [randomCompanyDetails, setCompanyDetails] = useState("");
  const [randomNoOfEmployees, setNoOfEmployees] = useState("");
  const [randomRegisterType, setRegisterType] = useState("");
  const [randomDescription, setDescription] = useState("");
  const [randomGitHub, setGitHub] = useState("");
  const address = [
    "Laxmi Nagar",
    "Preet Vihar",
    "Govindpuram",
    "Vijay Nagar",
    "Raj Nagar",
  ];
  const contact = [
    "1237698764",
    "4565674678",
    "7893498767",
    "3453657876",
    "2348765876",
  ];
  const companydetails = [
    "Accounting and Finance",
    "Customer Service or Operations",
    "Engineering Or Product Management",
    "Human Resource Management",
    "PHP Developer",
    "Production",
    "Purchasing",
    "Research and Development",
    "Sales",
  ];
  const noofemployees = [
    "Its Just Me",
    "10 - 99",
    "100 - 499 Employees",
    "500 - 1000 Employees",
    "More Than 1000",
  ];
  const registertypes = [
    "As an individual/Proprietor",
    "As an individual/Proprietor",
    "As an individual/Proprietor",
    "As an individual/Proprietor",
    "As an individual/Proprietor",
  ];
  const descriptions = ["Testing", "Dummy", "Testing", "Dummy", "Testing"];
  const github = [
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
  ];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * address.length,
      contact.length,
      companydetails.length,
      noofemployees.length,
      registertypes.length,
      descriptions.length,
      github.length,
    );

    const randomAddress = address[randomIndex];
    setAddress(randomAddress);

    const randomContact = contact[randomIndex];
    setContact(randomContact);

    const randomCompanyDetails = companydetails[randomIndex];
    setCompanyDetails(randomCompanyDetails);

    const randomNoOfEmployees = noofemployees[randomIndex];
    setNoOfEmployees(randomNoOfEmployees);

    const randomRegisterType = registertypes[randomIndex];
    setRegisterType(randomRegisterType);

    const randomDescription = descriptions[randomIndex];
    setDescription(randomDescription);

    const randomGitHub = github[randomIndex];
    setGitHub(randomGitHub);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  
  // const userAddressChange = (e) => {
  //   setAddress(e.target.value);
  // };
  const userContactChange = (e) => {
    setContact(e.target.value);
  };
  const userCompanyDetailsChange = (e) => {
    setCompanyDetails(e.target.value);
  };

  const userNoOfEmployeesChange = (e) => {
    setNoOfEmployees(e.target.value);
  };

  // const userRegisterTypeChange = (e) => {
  //   setRegisterType(e.target.value);
  // };

  const userDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const userGitHubChange = (e) => {
    setGitHub(e.target.value);
  };

  // dynamic data ke lie
  const [data, setData] = useState({
    fname: "",
    lname: "",

    registerType: "",
    dateOfBirth:"",
    phone: "",
    description: "",
    companyDetailsDepartment:"",
    numberOfEmployees:"",

    facebook: "",
    github: "",
    instagram: "",
    linkedIn: "",
    othersLink: "",
    stackOverflow: "",
    youtube: "",

  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const [userSelectedFile, setUserSelectedFile] = useState("");
  // const [userCompanyLogoSelectedFile, setCompanyLogoSelectedFile] = useState("");
  // const [userProfilePictureSelectedFile, setProfilePictureSelectedFile] = useState("");
  const [broucherSelectedFile, setBroucherSelectedFile] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const responselogin = await axiousPublic.post(`/auth/login`, {
        email,
        password,
      });
      console.log("userlogindataID", responselogin.data.data.user.userId);
      const profileClientId = responselogin.data.data.user.userId;

      const config11 = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + responselogin.data.data.token,
        },
      };

      const formData = new FormData();
        formData.append("registerType", randomRegisterType);
        formData.append("dateOfBirth", "Jul 04, 2024"); //randomDateOfBirth
        formData.append("phone", randomContact);
        formData.append("description", randomDescription);
        formData.append("companyDetailsDepartment", randomCompanyDetails);
        formData.append("numberOfEmployees", randomNoOfEmployees);
        formData.append("completedStep", 2);
        formData.append("Identity", userSelectedFile);
        formData.append("companyLogo", userSelectedFile);
        formData.append("profilePicture", userSelectedFile);
          
      const respstep1 = await axiousPublic.patch(`/clientProfile/${profileClientId}`, formData, config11);
      console.log(respstep1.data);

      const profile_client_step2 = {
        completedStep: 3,
        facebook: data.facebook || randomGitHub,
        github: data.github || randomGitHub,
        instagram: data.instagram || randomGitHub,
        linkedIn: data.linkedIn || randomGitHub,
        othersLink: data.othersLink || randomGitHub,
        stackOverflow: data.stackOverflow || randomGitHub,
        youtube: data.youtube || randomGitHub,
      };
      const respstep2 = await axiousPublic.patch(`/clientProfile/${profileClientId}`, profile_client_step2, config11);
      console.log(respstep2.data);

      const profile_client_step3 = new FormData();
        profile_client_step3.append("brochures", broucherSelectedFile);
        profile_client_step3.append("completedStep", 4);
        const respstep3 = await axiousPublic.post(`/clientProfile/brochures/${profileClientId}`, profile_client_step3, config11);
        console.log(respstep3.data);

      // alert("Profile data inserted");
      alert("Client Profile Fill Up Created Successfully !!!");

      window.location.reload();
    } catch (error) {
      console.error(error.message);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    }
    finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };


  return (
    <>
      <Header />

      <div className="container">
      <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
        <form onSubmit={formSubmit}>
        {/* <form> */}
          <div className="column jumbotron box8">
            <h4 className=" text-info mb-5 texth4">
              Profile Fill Up by Existing Client With Individual
            </h4>

            {/* <div className="form-group row">
              <label htmlFor="emailid" className="col-sm-5 col-form-label">
                Client Email ID
              </label>
              <div className="col-sm-7">
                <input
                  type="Email"
                  name="emailid"
                  id="emailid"
                  className="form-control"
                    onChange={(event) => emailid(event.target.value)}
                  required="true"
                />
              </div>
            </div> */}

              <div className="form-group row">
                <label htmlFor="email" className="col-sm-5 col-form-label">
                  Client Email ID
                </label>
                <div className="col-sm-7">
                  <input
                    type="Email"
                    name="email"
                    id="email"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setEmail(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-sm-5 col-form-label">
                  Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setPassword(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
              <label htmlFor="registerType" className="col-sm-5 col-form-label">
                RegisterType
              </label>
              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="registerType"
                  id="registerType"
                  placeholder=""
                  required=""
                  value={randomRegisterType}
                  // value={data.registerType || randomRegisterType}
                  // onChange={(event) => {
                  //   inputEvent(event);
                  //   userRegisterTypeChange(event);
                  // }}
                />
              </div>
            </div>

            <div className="form-group row">
                <label
                  htmlFor=""
                  className="col-sm-5 col-form-label"
                >
                  Date Of Birth
                </label>
                <div className="col-sm-7">
                  <input
                    type="date"
                    className="form-control"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    placeholder=""
                    required=""
                    value={data.dateOfBirth } //|| randomRegisterType
                    onChange={(event) => {
                      inputEvent(event);
                      // userRegisterTypeChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="phone"
                  className="col-sm-5 col-form-label"
                >
                  Phone
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="phone"
                    placeholder=""
                    required=""
                    // value={randomContact} onChange={userContactChange}
                    value={data.phone || randomContact}
                    // onChange={inputEvent}
                    onChange={(event) => {
                      inputEvent(event);
                      userContactChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="description"
                  className="col-sm-5 col-form-label"
                >
                  Description
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    id="description"
                    placeholder=""
                    required=""
                    value={data.description || randomDescription}
                    onChange={(event) => {
                      inputEvent(event);
                      userDescriptionChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
              <label
                htmlFor="companyDetailsDepartment"
                className="col-sm-5 col-form-label"
              >
                Company Details
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="companyDetailsDepartment"
                  id="companyDetailsDepartment"
                  placeholder=""
                  required=""
                    value={data.companyDetailsDepartment || randomCompanyDetails}
                    onChange={(event) => {
                      inputEvent(event);
                      userCompanyDetailsChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="numberOfEmployees"
                className="col-sm-5 col-form-label"
              >
                No. Of Employees
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="numberOfEmployees"
                  id="numberOfEmployees"
                  placeholder=""
                  required=""
                    value={data.numberOfEmployees || randomNoOfEmployees}
                    onChange={(event) => {
                      inputEvent(event);
                      userNoOfEmployeesChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
                <label
                  htmlFor="Identity"
                  className="col-sm-5 col-form-label"
                >
                  Identity
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="Identity"
                    name="Identity"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true" 
                  />
                </div>
              </div>

            <div className="form-group row">
                <label
                  htmlFor="companyLogo"
                  className="col-sm-5 col-form-label"
                >
                  CompanyLogo
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="companyLogo"
                    name="companyLogo"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true"  
                  />
                </div>
              </div>

            <div className="form-group row">
                <label
                  htmlFor="profilePicture"
                  className="col-sm-5 col-form-label"
                >
                  Profile Picture
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="profilePicture"
                    name="profilePicture"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true" 
                  />
                </div>
              </div>

            <div className="form-group row">
              <label htmlFor="facebook" className="col-sm-5 col-form-label">
                Facebook
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="facebook"
                  id="facebook"
                  placeholder=""
                  required=""
                    value={data.facebook || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="github" className="col-sm-5 col-form-label">
                GitHub
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="github"
                  id="github"
                  placeholder=""
                  required=""

                    value={data.github || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="instagram" className="col-sm-5 col-form-label">
                Instagram
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="instagram"
                  id="instagram"
                  placeholder=""
                  required=""
                    value={data.instagram || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="linkedIn" className="col-sm-5 col-form-label">
                LinkedIn
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="linkedIn"
                  id="linkedIn"
                  placeholder=""
                  required=""
                    value={data.linkedIn || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="othersLink" className="col-sm-5 col-form-label">
                OthersLink
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="othersLink"
                  id="othersLink"
                  placeholder=""
                  required=""
                     value={data.othersLink || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="stackOverflow"
                className="col-sm-5 col-form-label"
              >
                StackOverflow
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="stackOverflow"
                  id="stackOverflow"
                  placeholder=""
                  required=""
                     value={data.stackOverflow || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="youtube" className="col-sm-5 col-form-label">
                YouTube
              </label>

              <div className="col-sm-7">
                <input
                  type="text"
                  className="form-control"
                  name="youtube"
                  id="youtube"
                  placeholder=""
                  required=""
                     value={data.youtube || randomGitHub}
                    onChange={(event) => {
                      inputEvent(event);
                      userGitHubChange(event);
                    }}
                />
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="clientBrochures"
                className="col-sm-5 col-form-label"
              >
                Brochures
              </label>

              <div className="col-sm-7">
                <input
                 type="file"
                 id="brochures"
                 name="brochures"
                 accept="image/*"
                 onChange={(e) => setBroucherSelectedFile(e.target.files[0])}
                 className="form-control"
                 required="true"
                />
              </div>
            </div>

            <br />

            <div className=" form-group mb-0">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
         )}
      </div>
    </>
  );
};

export default ProfilefillClientIndivdual;
