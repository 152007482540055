import React, { useEffect, useState } from "react";
import Header from "../Header";
import "./postjobopening.css";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "../Hooks/useAxiosPublic";


const ProfilefillupFreelancer = () => {
  const axiousPublic = useAxiosPublic();

  // const [email_id, emailid] = useState("");
  const [randomInterestedRole, setInterestedRole] = useState("");
  const [randomInterestedLocation, setInterestedLocation] = useState("");
  const [randomTitle, setTitle] = useState("");

  const [randomCurrentSalaryCtc, setCurrentSalaryCtc] = useState("");
  const [randomFixedSalaryCtc, setFixedSalaryCtc] = useState("");
  const [randomExpectedSalaryCtc, setExpectedSalaryCtc] = useState("");
  const [randomMuchExpectedSalaryCtc, setMuchExpectedSalaryCtc] = useState("");
  const [randomStartedPeriod, setStartedPeriod] = useState("");
  const [randomLongPeriod, setLongPeriod] = useState("");
  const [randomNegotiablePeriod, setNegotiablePeriod] = useState("");
  const [randomOkaytoRemoteJob, setOkaytoRemoteJob] = useState("");
  const [randomCitiesNcr, setCitiesNcr] = useState("");
  const [randomDesireCitiesWorking, setDesireCitiesWorking] = useState("");

  const [randomFullTimeJobExperience, setFullTimeJobExperience] = useState("");
  const [randomReportPeople, setReportPeople] = useState("");
  const [randomTopSkill, setTopSkill] = useState("");

  const [randomDescribeJobIda, setDescribeJobIda] = useState("");

  const [randomHearAboutUs, setHearAboutUs] = useState("");
  const [randomJobSearch, setJobSearch] = useState("");
  const [randomGitHub, setGitHub] = useState("");

  const [randomHourlyRateFrom, setHourlyRateFrom] = useState("");
  const [randomHourlyRateTo, setHourlyRateTo] = useState("");
  const [randomReadyToInterview, setReadyToInterview] = useState("");

  const interestedRoles = ['["front end developer","ios developer","management skills"]','["front end developer", "full stack developer", "game developer", "ios developer"]', '["full stack developer","management skills"]', '["full stack developer","game developer"]', '["ios developer","management skills","full stack developer"] '];

  const interestedlocation = [
    "Delhi/NCR",
    "Out of NCR",
    "Other",
    "Delhi/NCR",
    "Out of NCR",
  ];
  const title = ["NodeJs", "java Js", "php Js", "python Js", "django Js"];

  const currentsalaryCtc = ["1", "2", "3", "4", "5"];
  const fixedsalaryCtc = ["2", "5", "7", "4", "9"];
  const expectedsalaryCtc = ["3", "6", "4", "8", "1"];
  const muchExpectedsalaryCtc = ["5", "7", "8", "6", "3"];
  const startedperiod = ["no", "no", "no", "no", "no"];
  // const startedperiod = ["No, not resigned yet", "Yes", "No", "Yes", "No"];
  const longperiod = ["1 week", "2 months", "15 days", "2 months", "1 week"];
  const negotiableperiod = [
    "Yes",
    "No, not resigned yet",
    "No",
    "Yes",
    "No, not resigned yet",
  ];
  const okaytoremotejob = [
    "Okay with remote/in-office jobs",
    "No",
    "Okay with remote/in-office jobs",
    "No",
    "Okay with remote/in-office jobs",
  ];
  const citiesNcr = ["Delhi", "Noida", "haryana", "faridabad", "gurgao"];
  const desirecitiesworking = ["haryana", "hisar", "Delhi", "Noida", "punjab"];

  const full_time_job_experience = [
    "0-2 years",
    "2-4 years",
    "4-6 years",
    "6-8 years",
    "8-10 years",
  ];
  const report_people = [
    "Not managing a team",
    "Less than 10 people",
    "More than 10 people",
    "Not managing a team",
    "Less than 10 people",
  ];
  // const top_skill = ['["backend developer"]'];
  const skills = [
    '{"html":"Beginner","css":"Intermediate","react":"Advanced"}',
    '{"react":"Intermediate"}',
    '{"sql":"Advanced"}',
    '{"html":"Expert","css":"Expert"}',
    '{"sql":"Expert"}',
  ];

  const describeJobIda = [
    '{"Seed (1-10)":"Ideal","Early (11-50)":"Yes","Mid-Size (51-200)":"No","Large (201-500)":"No","Very-Large (501-1000)":"Yes","Massive (1000+)":"Ideal"}',
    '{"Seed (1-10)":"Ideal","Early (11-50)":"Ideal","Mid-Size (51-200)":"Yes","Large (201-500)":"Yes","Very-Large (501-1000)":"No","Massive (1000+)":"No"}',
    '{"Seed (1-10)":"Ideal","Early (11-50)":"Yes","Mid-Size (51-200)":"No","Large (201-500)":"No","Very-Large (501-1000)":"Yes","Massive (1000+)":"Ideal"}',
    '{"Seed (1-10)":"Ideal","Early (11-50)":"Ideal","Mid-Size (51-200)":"Yes","Large (201-500)":"Yes","Very-Large (501-1000)":"No","Massive (1000+)":"No"}',
    '{"Seed (1-10)":"Ideal","Early (11-50)":"Yes","Mid-Size (51-200)":"No","Large (201-500)":"No","Very-Large (501-1000)":"Yes","Massive (1000+)":"Ideal"}',
  ];

  const hear_aboutUs = [
    "Word of Mouth",
    "Job Portal",
    "Advertisement",
    "Job Portal",
    "Advertisement",
  ];
  const job_search = [
    "Open to new opportunities",
    "variety of opportunities",
    "Open to new opportunities",
    "variety of opportunities",
    "Open to new opportunities",
  ];
  const github = [
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
  ];

  const HourlyRateFrom = ["5", "7", "8", "6", "3"];
  const HourlyRateTo = ["5", "7", "8", "6", "3"];
  const readyToInterview = [
    "05 days",
    "10 days",
    "15 days",
    "30 days",
    "01 month",
  ];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * interestedRoles.length,
      interestedlocation.length,
      title.length,
      currentsalaryCtc.length,
      fixedsalaryCtc.length,
      expectedsalaryCtc.length,
      muchExpectedsalaryCtc.length,
      startedperiod.length,
      longperiod.length,
      negotiableperiod.length,
      okaytoremotejob.length,
      citiesNcr.length,
      desirecitiesworking.length,
      full_time_job_experience.length,
      report_people.length,
      skills.length,
      describeJobIda.length,
      hear_aboutUs.length,
      job_search.length,
      github.length,
      HourlyRateFrom.length,
      HourlyRateTo.length,
      readyToInterview.length,
    );

    const randomInterestedRole = interestedRoles[randomIndex];
    setInterestedRole(randomInterestedRole);

    const randomInterestedLocation = interestedlocation[randomIndex];
    setInterestedLocation(randomInterestedLocation);

    const randomTitle = title[randomIndex];
    setTitle(randomTitle);

    const randomCurrentSalaryCtc = currentsalaryCtc[randomIndex];
    setCurrentSalaryCtc(randomCurrentSalaryCtc);

    const randomFixedSalaryCtc = fixedsalaryCtc[randomIndex];
    setFixedSalaryCtc(randomFixedSalaryCtc);

    const randomExpectedSalaryCtc = expectedsalaryCtc[randomIndex];
    setExpectedSalaryCtc(randomExpectedSalaryCtc);

    const randomMuchExpectedSalaryCtc = muchExpectedsalaryCtc[randomIndex];
    setMuchExpectedSalaryCtc(randomMuchExpectedSalaryCtc);

    const randomStartedPeriod = startedperiod[randomIndex];
    setStartedPeriod(randomStartedPeriod);

    const randomLongPeriod = longperiod[randomIndex];
    setLongPeriod(randomLongPeriod);

    const randomNegotiablePeriod = negotiableperiod[randomIndex];
    setNegotiablePeriod(randomNegotiablePeriod);

    const randomOkaytoRemoteJob = okaytoremotejob[randomIndex];
    setOkaytoRemoteJob(randomOkaytoRemoteJob);

    const randomCitiesNcr = citiesNcr[randomIndex];
    setCitiesNcr(randomCitiesNcr);

    const randomDesireCitiesWorking = desirecitiesworking[randomIndex];
    setDesireCitiesWorking(randomDesireCitiesWorking);

    const randomFullTimeJobExperience = full_time_job_experience[randomIndex];
    setFullTimeJobExperience(randomFullTimeJobExperience);

    const randomReportPeople = report_people[randomIndex];
    setReportPeople(randomReportPeople);

    const randomTopSkill = skills[randomIndex];
    setTopSkill(randomTopSkill);

    const randomDescribeJobIda = describeJobIda[randomIndex];
    setDescribeJobIda(randomDescribeJobIda);

    const randomHearAboutUs = hear_aboutUs[randomIndex];
    setHearAboutUs(randomHearAboutUs);

    const randomJobSearch = job_search[randomIndex];
    setJobSearch(randomJobSearch);

    const randomGitHub = github[randomIndex];
    setGitHub(randomGitHub);

    const randomHourlyRateFrom = HourlyRateFrom[randomIndex];
    setHourlyRateFrom(randomHourlyRateFrom);
    
    const randomHourlyRateTo = HourlyRateTo[randomIndex];
    setHourlyRateTo(randomHourlyRateTo);

    const randomReadyToInterview = readyToInterview[randomIndex];
    setReadyToInterview(randomReadyToInterview);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  const userInterestedRoleChange = (e) => {
    setInterestedRole(e.target.value);
  };
  // const userInterestedLocationChange = (e) => {
  //   setInterestedLocation(e.target.value);
  // };
  const userTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const userCurrentSalaryCtcChange = (e) => {
    setCurrentSalaryCtc(e.target.value);
  };
  const userFixedSalaryCtcChange = (e) => {
    setFixedSalaryCtc(e.target.value);
  };
  const userExpectedSalaryCtcChange = (e) => {
    setExpectedSalaryCtc(e.target.value);
  };
  const userMuchExpectedSalaryCtcChange = (e) => {
    setMuchExpectedSalaryCtc(e.target.value);
  };
  const userStartedPeriodChange = (e) => {
    setStartedPeriod(e.target.value);
  };
  const userLongPeriodChange = (e) => {
    setLongPeriod(e.target.value);
  };
  const userNegotiablePeriodChange = (e) => {
    setNegotiablePeriod(e.target.value);
  };
  // const userOkaytoRemoteJobChange = (e) => {
  //   setOkaytoRemoteJob(e.target.value);
  // };
  // const userCitiesNcrChange = (e) => {
  //   setCitiesNcr(e.target.value);
  // };
  // const userDesireCitiesWorkingChange = (e) => {
  //   setDesireCitiesWorking(e.target.value);
  // };

  const userFullTimeJobExperienceChange = (e) => {
    setFullTimeJobExperience(e.target.value);
  };
  const userReportPeopleChange = (e) => {
    setReportPeople(e.target.value);
  };
  const userTopSkillChange = (e) => {
    setTopSkill(e.target.value);
  };

  // const userDescribeJobIdaChange = (e) => {
  //   setDescribeJobIda(e.target.value);
  // };

  const userHearAboutUsChange = (e) => {
    setHearAboutUs(e.target.value);
  };

  const userJobSearchChange = (e) => {
    setJobSearch(e.target.value);
  };

  const userGitHubChange = (e) => {
    setGitHub(e.target.value);
  };

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  // dynamic data ke lie
  const [data, setData] = useState({

    interestedRoles: "",
    dateOfBirth: "",
    currentCtc: "",
    expectedCtc: "",
    expectedFixedCtc: "",
    fixedCtc: "",
    hourlyRateFrom: "",
    hourlyRateTo: "",
    negotiablePeriod: "",
    startPeriod: "",
    title: "",
    longPeriod: "",
    isFresher: "",
    currentCompanyName: "",
    currentDesignation: "",
    currentlyEmployed: "",
    employmentType: "",
    endDate: "",
    salary: "",
    skillsUsed: "",
    startDate: "",
    summary: "",
    workingSummaryDetails: "",
    addEducation: "",
    boardName: "",
    englishMarks: "",
    mathMarks: "",
    schoolMarks: "",
    schoolMedium: "",
    schoolPassingYear: "",
    fullTimeJobExperience: "",
    github: "",
    linkedIn: "",
    portfolio: "",
    reportPeople: "",
    skills: "",
    stackOverflow: "",
    hearAboutUs: "",
    jobSearch: "",
    readyToInterview: "",
    whyOurCompany: "",
    biggestAchievement: "",
    profilePicture: "",
    // ----------------------------------------------------------------
    freelancerId: "",
    
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const [userSelectedFile, setUserSelectedFile] = useState("");


  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const responselogin = await axiousPublic.post(`/auth/login`, {
        email,
        password,
      });
      console.log("userlogindataID", responselogin.data.data.user.userId);

      const profilefreelancerId = responselogin.data.data.user.userId; //data.freelancerId || "234"; // or get it from somewhere
      const config11 = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + responselogin.data.data.token,
        },
      };

      const profile_freelancer_step1 = {
        // interestedRoles: data.interestedRoles || randomInterestedRole,
        interestedRoles: ["front end developer", "full stack developer", "game developer", "ios developer"],
        completedStep: 1,
      };
      const respstep1 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step1, config11);
      console.log(respstep1.data);

      const date_OfBirth_data = {
        dateOfBirth: "Apr 19, 2024",
      };
      const dateOfBirthdata = await axiousPublic.patch(`/users/${profilefreelancerId}`, date_OfBirth_data, config11);
      console.log(dateOfBirthdata.data);

      const profile_freelancer_step2 = {
        completedStep: 2,
        currentCtc: randomCurrentSalaryCtc, //11,
        expectedCtc: randomExpectedSalaryCtc, // 13,
        // expectedFixedCtc: randomFixedSalaryCtc, //7,
        // fixedCtc: randomMuchExpectedSalaryCtc, //9,
        hourlyRateFrom: randomHourlyRateFrom ,//100,
        hourlyRateTo: randomHourlyRateTo, //120,
        negotiablePeriod: randomNegotiablePeriod, //"NO, not resigned yet",
        startPeriod: randomStartedPeriod, //"NO, not resigned yet",
        title: randomTitle, //"dummy testing",
        // longPeriod: randomLongPeriod, //"2 months",
      };
      const respstep2 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step2, config11);
      console.log(respstep2.data);

      const profile_freelancer_step3 = {
        completedStep: 3,
        isFresher: false,
          jobHistory: [
            {
              currentCompanyName: "Hire",
              currentDesignation: "Developer",
              currentlyEmployed: "yes",
              employmentType: "Full Time",
              endDate: "Apr 08, 2024",
              salary: "50000",
              skillsUsed: ["html"],
              startDate: "Apr 08, 2023",
              summary: "Hello Developer",
              workingSummaryDetails: "developer",
            },
          ],
      };
      const respstep3 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step3, config11);
      console.log(respstep3.data);

      const profile_freelancer_step4 = {
        completedStep: 4,
        education: [
          {
            addEducation: "10th",
            boardName: "Haryana",
            englishMarks: "98",
            mathMarks: "80",
            schoolMarks: "80- 84.9%",
            schoolMedium: "Hindi",
            schoolPassingYear: "Jul 10, 2024",
          },
        ],
      };
      const respstep4 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step4, config11);
      console.log(respstep4.data);

      const profile_freelancer_step5 = {
        completedStep: 5,
        fullTimeJobExperience: randomFullTimeJobExperience, //"1 years",
        github: randomGitHub, //"https://www.linkedin.com/",
        linkedIn: randomGitHub, //"https://www.linkedin.com/",
        portfolio: randomGitHub, //"https://www.linkedin.com/",
        reportPeople: randomReportPeople, //"Not managing a team",
        // skills: randomTopSkill, //["htmlcss"],
        skills: ["HTML", "CSS"],
        stackOverflow: randomGitHub, //"https://www.linkedin.com/",
      };
      const respstep5 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step5, config11);
      console.log(respstep5.data);

      const profile_freelancer_step6 = {
        hearAboutUs: randomHearAboutUs, //"Job Portal",
        jobSearch: randomJobSearch, //"Open to new opportunities",
        readyToInterview: randomReadyToInterview, //"Yes",
        whyOurCompany: "I want to grow my skills" , //randomNegotiablePeriod, //"testing",
        biggestAchievement: "Giving a great presentation at work", //"dummyyy",
        completedStep: 6,
      };
      const respstep6 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, profile_freelancer_step6, config11);
      console.log(respstep6.data);

      const profile_complete1 = {
        isProfileCompleted: true,
        profilePicture: {},
      };
      const respsteppc = await axiousPublic.patch(`/users/${profilefreelancerId}`, profile_complete1, config11);
      console.log(respsteppc.data); 

      const formData = new FormData();
      formData.append("profilePicture", userSelectedFile);
      formData.append("completedStep", 7);
      formData.append("isProfileCompleted", true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + responselogin.data.data.token,
        },
      };
      const respstep7 = await axiousPublic.patch(`/freelancerProfile/${profilefreelancerId}`, formData, config);
      console.log(respstep7.data);

      alert("Freelancer Profile Fill Up Created Successfully !!!");
      window.location.reload();
    } catch (error) {
      console.error(error.message);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    } finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };

  return (
    <>
      <Header />

      <div className="container">
        <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
          <form onSubmit={formSubmit}>
            <div className="column jumbotron box8">
              <h4 className=" text-info mb-5 texth4">
                Profile Fill Up by Existing Freelancer
              </h4>

              <div className="form-group row">
                <label htmlFor="email" className="col-sm-5 col-form-label">
                  Freelancer Email ID
                </label>
                <div className="col-sm-7">
                  <input
                    type="Email"
                    name="email"
                    id="email"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setEmail(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="password" className="col-sm-5 col-form-label">
                  Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    // onChange={(event) => emailid(event.target.value)}
                    onChange={(e) => setPassword(e.target.value)}
                    required="true"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="interestedRoles"
                  className="col-sm-5 col-form-label"
                >
                  Interested Role
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="interestedRoles"
                    id="interestedRoles"
                    placeholder="Enter your Role"
                    required=""
                    value={data.interestedRoles || randomInterestedRole}
                    onChange={(event) => {
                      // inputEvent(event);
                      userInterestedRoleChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="title"
                  className="col-sm-5 col-form-label"
                >
                  Title
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="title"
                    id="title"
                    placeholder="Enter your Title"
                    required=""
                    // value={data.profile_title || randomTitle}
                    value={randomTitle}
                    onChange={(event) => {
                      userTitleChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="dateOfBirth"
                  className="col-sm-5 col-form-label"
                >
                  Date of Birthday
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="dateOfBirth"
                    id="dateOfBirth"
                    placeholder="Enter your Birth"
                    required=""
                    // value={data.dateOfBirth || randomTitle}
                    // value={randomTitle}
                    onChange={(event) => {
                      // userTitleChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="currentCtc" className="col-sm-5 col-form-label">
                  What is your current CTC?
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="currentCtc"
                    id="currentCtc"
                    placeholder=""
                    required=""
                    value={randomCurrentSalaryCtc}
                    // value={data.currentCtc || randomCurrentSalaryCtc}
                    onChange={(event) => {
                      userCurrentSalaryCtcChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="fixedCtc" className="col-sm-5 col-form-label">
                  How much of your CTC is fixed?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="fixedCtc"
                    id="fixedCtc"
                    placeholder=""
                    required=""
                    value={randomFixedSalaryCtc}
                    // value={data.fixedCtc || randomFixedSalaryCtc}
                    onChange={(event) => {
                      userFixedSalaryCtcChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="expectedCtc"
                  className="col-sm-5 col-form-label"
                >
                  What is your Minimum Expected Total CTC?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="expectedCtc"
                    id="expectedCtc"
                    placeholder=""
                    required=""
                    value={randomExpectedSalaryCtc}
                    // value={data.expectedCtc || randomExpectedSalaryCtc}
                    onChange={(event) => {
                      userExpectedSalaryCtcChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="muchExpectedCtc"
                  className="col-sm-5 col-form-label"
                >
                  Of The total expected CTC , how much do you expect as a fixed?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="muchExpectedCtc"
                    id="muchExpectedCtc"
                    placeholder=""
                    required=""
                    value={randomMuchExpectedSalaryCtc}
                    // value={data.muchExpectedCtc || randomMuchExpectedSalaryCtc}
                    onChange={(event) => {
                      userMuchExpectedSalaryCtcChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="hourlyRateFrom"
                  className="col-sm-5 col-form-label"
                >
                  Hourly Rate From:
                </label>
                <div className="col-sm-7">
                <input
                    type="text"
                    className="form-control"
                    name="hourlyRateFrom"
                    id="hourlyRateFrom"
                    placeholder=""
                    required=""
                    value={randomHourlyRateFrom}
                    //  onChange={(event) => {
                    //   userHourlyRateFromChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="hourlyRateTo"
                  className="col-sm-5 col-form-label"
                >
                  Hourly Rate To:
                </label>
                <div className="col-sm-7">
                <input
                    type="text"
                    className="form-control"
                    name="hourlyRateTo"
                    id="hourlyRateTo"
                    placeholder=""
                    required=""
                    value={randomHourlyRateTo}
                    //  onChange={(event) => {
                    //   userHourlyRateFromChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="startPeriod"
                  className="col-sm-5 col-form-label"
                >
                  Have you started serving your notice period?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="startPeriod"
                    id="startPeriod"
                    placeholder=""
                    required=""
                    value={randomStartedPeriod}
                    // value={data.startedPeriod || randomStartedPeriod}
                    onChange={(event) => {
                      userStartedPeriodChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="longPeriod" className="col-sm-5 col-form-label">
                  How long is your notice period?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="longPeriod"
                    id="longPeriod"
                    placeholder=""
                    required=""
                    value={randomLongPeriod}
                    // value={data.longPeriod || randomLongPeriod}
                    onChange={(event) => {
                      userLongPeriodChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="negotiablePeriod"
                  className="col-sm-5 col-form-label"
                >
                  Is your notice period negotiable
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="negotiablePeriod"
                    id="negotiablePeriod"
                    placeholder=""
                    required=""
                    value={randomNegotiablePeriod}
                    // value={data.negotiablePeriod || randomNegotiablePeriod}
                    onChange={(event) => {
                      userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="isFresher"
                  className="col-sm-5 col-form-label"
                >
                  Are You Fresher?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="isFresher"
                    id="isFresher"
                    placeholder=""
                    required=""
                    value="No"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="workingSummaryDetails"
                  className="col-sm-5 col-form-label"
                >
                  Career Work Summary
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="workingSummaryDetails"
                    id="workingSummaryDetails"
                    placeholder=""
                    required=""
                    value="developer"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="currentlyEmployed"
                  className="col-sm-5 col-form-label"
                >
                  Is This your Employment
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="currentlyEmployed"
                    id="currentlyEmployed"
                    placeholder=""
                    required=""
                    value="yes"
                    onChange={(event) => {
                      // userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="employmentType"
                  className="col-sm-5 col-form-label"
                >
                  Employment Type
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="employmentType"
                    id="employmentType"
                    placeholder=""
                    required=""
                    value="Full Time"
                    onChange={(event) => {
                      // userNegotiablePeriodChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="currentCompanyName"
                  className="col-sm-5 col-form-label"
                >
                 Current Company Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="currentCompanyName"
                    id="currentCompanyName"
                    placeholder=""
                    required=""
                    value="Hire"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="currentDesignation"
                  className="col-sm-5 col-form-label"
                >
                 Current Designation
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="currentDesignation"
                    id="currentDesignation"
                    placeholder=""
                    required=""
                    value="Developer"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="startDate"
                  className="col-sm-5 col-form-label"
                >
                 Joining Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="startDate"
                    id="startDate"
                    placeholder=""
                    required=""
                    value="Apr 08, 2023"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="endDate"
                  className="col-sm-5 col-form-label"
                >
                 Ending Date
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="endDate"
                    id="endDate"
                    placeholder=""
                    required=""
                    value="Apr 08, 2024"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="salary"
                  className="col-sm-5 col-form-label"
                >
                 Current Salary
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="salary"
                    id="salary"
                    placeholder=""
                    required=""
                    value="50000"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="skillsUsed"
                  className="col-sm-5 col-form-label"
                >
                 Skill Used
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="skillsUsed"
                    id="skillsUsed"
                    placeholder=""
                    required=""
                    value={["html"]}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="summary"
                  className="col-sm-5 col-form-label"
                >
                 Summary
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="summary"
                    id="summary"
                    placeholder=""
                    required=""
                    value="Hello Developer"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="addEducation"
                  className="col-sm-5 col-form-label"
                >
                 Education
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="addEducation"
                    id="addEducation"
                    placeholder=""
                    required=""
                    value="10th"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="boardName"
                  className="col-sm-5 col-form-label"
                >
                 Board
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="boardName"
                    id="boardName"
                    placeholder=""
                    required=""
                    value="Haryana"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="schoolPassingYear"
                  className="col-sm-5 col-form-label"
                >
                 Passing out Year
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="schoolPassingYear"
                    id="schoolPassingYear"
                    placeholder=""
                    required=""
                    value="Jul 10, 2024"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="schoolMedium"
                  className="col-sm-5 col-form-label"
                >
                 School Medium
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="schoolMedium"
                    id="schoolMedium"
                    placeholder=""
                    required=""
                    value="Hindi"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="schoolMarks"
                  className="col-sm-5 col-form-label"
                >
                 Total Marks
                </label>
                <div className="col-sm-7">
                  <input
                     type="text"
                     className="form-control"
                     name="schoolMarks"
                     id="schoolMarks"
                     placeholder=""
                     required=""
                     value="80- 84.9%"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="englishMarks"
                  className="col-sm-5 col-form-label"
                >
                 English Marks
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="englishMarks"
                    id="englishMarks"
                    placeholder=""
                    required=""
                    value="98"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="mathMarks"
                  className="col-sm-5 col-form-label"
                >
                  Math Marks
                </label>
                <div className="col-sm-7">
                  <input
                   type="text"
                   className="form-control"
                   name="mathMarks"
                   id="mathMarks"
                   placeholder=""
                   required=""
                   value="80"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="fullTimeJobExperience"
                  className="col-sm-5 col-form-label"
                >
                  How many years do you have a full time experience?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="fullTimeJobExperience"
                    id="fullTimeJobExperience"
                    placeholder=""
                    required=""
                    value={randomFullTimeJobExperience}
                    // value={data.full_time_job_experience || randomFullTimeJobExperience}
                    onChange={(event) => {
                      userFullTimeJobExperienceChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="reportPeople"
                  className="col-sm-5 col-form-label"
                >
                  How many people report to you ? (Directly or Indirectly)
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="reportPeople"
                    id="reportPeople"
                    placeholder=""
                    required=""
                    value={randomReportPeople}
                    // value={data.report_people || randomReportPeople}
                    onChange={(event) => {
                      userReportPeopleChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="skills" className="col-sm-5 col-form-label">
                  {/* Pick your top frameworks, skills and technologies.(up to 8) */}
                  Selected Experience Skills
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="skills"
                    id="skills"
                    placeholder=""
                    required=""
                    value={["HTML", "CSS"]}
                    // value={randomTopSkill}
                    // value={data.top_skill || randomTopSkill}
                    onChange={(event) => {
                      userTopSkillChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="github" className="col-sm-5 col-form-label">
                  GitHub
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="github"
                    id="github"
                    placeholder=""
                    required="true"
                    value={data.github || randomGitHub}
                    onChange={(event) => {
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="linkedIn" className="col-sm-5 col-form-label">
                  Linkedin
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="linkedIn"
                    id="linkedIn"
                    placeholder=""
                    required="true"
                    value={data.linkedIn || randomGitHub}
                    onChange={(event) => {
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="portfolio" className="col-sm-5 col-form-label">
                  Portfolio
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="portfolio"
                    id="portfolio"
                    placeholder=""
                    required="true"
                    value={data.portfolio || randomGitHub}
                    onChange={(event) => {
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="stackOverflow"
                  className="col-sm-5 col-form-label"
                >
                  StackOverflow
                </label>

                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="stackOverflow"
                    id="stackOverflow"
                    placeholder=""
                    required="true"
                    value={data.stackOverflow || randomGitHub}
                    onChange={(event) => {
                      userGitHubChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="hearAboutUs"
                  className="col-sm-5 col-form-label"
                >
                  How did you hear about us ?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="hearAboutUs"
                    id="hearAboutUs"
                    placeholder=""
                    required=""
                    value={randomHearAboutUs}
                    // value={data.hear_aboutUs || randomHearAboutUs}
                    onChange={(event) => {
                      userHearAboutUsChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label htmlFor="jobSearch" className="col-sm-5 col-form-label">
                  Where are you in your job search ?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="jobSearch"
                    id="jobSearch"
                    placeholder=""
                    required=""
                    value={randomJobSearch}
                    // value={data.job_search || randomJobSearch}
                    onChange={(event) => {
                      userJobSearchChange(event);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="readyToInterview"
                  className="col-sm-5 col-form-label"
                >
                  When will you be ready to start interviewing with company?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="readyToInterview"
                    id="readyToInterview"
                    placeholder=""
                    required=""
                    value={randomReadyToInterview}
                    // value={data.ready_to_interview || randomStartedPeriod}
                    // onChange={(event) => {
                    //   userStartedPeriodChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="whyOurCompany"
                  className="col-sm-5 col-form-label"
                >
                  Why do you want to work in our company?
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="whyOurCompany"
                    id="whyOurCompany"
                    placeholder=""
                    required=""
                    value={"I want to grow my skills"}
                    // value={data.hidden_form || randomNegotiablePeriod}
                    // onChange={(event) => {
                    //   userNegotiablePeriodChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="biggestAchievement"
                  className="col-sm-5 col-form-label"
                >
                  What is your biggest Achievement
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    name="biggestAchievement"
                    id="biggestAchievement"
                    placeholder=""
                    required=""
                    value={"Giving a great presentation at work"}
                    // value={data.achievement || randomLongPeriod}
                    // onChange={(event) => {
                    //   userLongPeriodChange(event);
                    // }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="profilePicture"
                  className="col-sm-5 col-form-label"
                >
                  Select Image
                </label>
                <div className="col-sm-7">
                  <input
                    type="file"
                    id="profilePicture"
                    name="profilePicture"
                    accept="image/*"
                    onChange={(e) => setUserSelectedFile(e.target.files[0])}
                    className="form-control"
                    required="true"  
                  />
                </div>
              </div>
              <br />

              <div className=" form-group mb-0">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ProfilefillupFreelancer;
