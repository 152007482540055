import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "react-js-loader";
import toast, { Toaster } from "react-hot-toast";
import useAxiosPublic from "./Hooks/useAxiosPublic";


const ClientWithCompany = () => {
  const axiousPublic = useAxiosPublic();

  const [randomName, setRandomName] = useState("");
  const [randomLastName, setRandomLastName] = useState("");
  const [randomUserName, setRandomUserName] = useState("");
  const [randomEmail, setRandomEmail] = useState("");
  const [randomCountryNames, setRandomCountryNames] = useState("");
  const [randomPasswords, setPasswords] = useState("");
  const [randomAddress, setAddress] = useState("");
  const [randomContact, setContact] = useState("");
  const [randomCompanyDetails, setCompanyDetails] = useState("");
  const [randomNoOfEmployees, setNoOfEmployees] = useState("");
  const [randomRegisterType, setRegisterType] = useState("");
  const [randomDescription, setDescription] = useState("");
  const [randomCompanyName, setCompanyName] = useState("");
  const [randomTagline, setTagline] = useState("");
  const [randomGSTINs, setGSTINs] = useState("");
  const [randomGitHub, setGitHub] = useState("");
  const [randomRegistrationNumberCIN, setRegistrationNumberCIN] = useState("");
  const [randomCompanyURL, setCompanyURL] = useState("");
  const [randomCompanyEmail, setCompanyEmail] = useState("");

  // clientjobpost
  const [randomProjectLevel, setrandomProjectLevel] = useState("");
  const [randomJobDuration, setrandomJobDuration] = useState("");
  const [randomFreelancerType, setrandomFreelancerType] = useState("");
  const [randomEnglishLevel, setrandomEnglishLevel] = useState("");
  const [
    randomYearsofExperiencePreferred,
    setrandomYearsofExperiencePreferred,
  ] = useState("");
  const [randomJobCategories, setrandomJobCategories] = useState("");
  const [randomProjectLocationType, setrandomProjectLocationType] =
    useState("");
  const [randomJobTitle, setrandomJobTitle] = useState("");
  const [randomWorkPrice, setrandomWorkPrice] = useState("");
  const [randomJobDetails, setrandomJobDetails] = useState("");
  const [randomJobLocation, setrandomJobLocation] = useState("");

  // checkbox
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);

  // verify email
  const [verificationResult, setVerificationResult] = useState(null);

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  };

  const RandomStringGenerator = () => {
    const [randomString, setRandomString] = useState("");
    useEffect(() => {
      const randomStr = generateRandomString(10); // Adjust the length as needed
      setRandomString(randomStr);
    }, []); // Empty dependency array ensures the effect runs only once when the component mounts
  };

  const names = ["John", "Ishaan", "Divya", "Kavi", "Aditi"];
  const lastNames = ["Doe", "Boe", "Dane", "Kumar", "Sharma"];
  const userNames = ["Koyal", "Varun", "Bobc", "Aliced", "Same"];

  const fixedEmail = "ngtmail101@gmail.com";
  const randomText = generateRandomString(10);
  //const emails =   {randomText:[0]}+{fixedEmail};
  const joinedString = randomText + fixedEmail;
  console.log("joinedString", joinedString);
  //const emailObject = { randomText: randomText, fixedEmail: fixedEmail };
  //console.log('object',emailObject)
  const emails = joinedString;
  console.log("email", emails);

  const passwords = ["Strange@1", "Strange@1", "Strange@1", "Strange@1", "Strange@1"];
  const countryNames = ["india", "india", "india", "india", "india"];
  const address = [
    "Laxmi Nagar",
    "Preet Vihar",
    "Govindpuram",
    "Vijay Nagar",
    "Raj Nagar",
  ];
  const contact = [
    "1237698764",
    "4565674678",
    "7893498767",
    "3453657876",
    "2348765876",
  ];
  const companydetails = ["Accounting and Finance", "Customer Service or Operations", "Engineering Or Product Management", "Human Resource Management", "PHP Developer", "Production", "Purchasing", "Research and Development", "Sales"];

  const noofemployees = [
    "Its Just Me",
    "10 - 99",
    "100 - 499 Employees",
    "500 - 1000 Employees",
    "More Than 1000",
  ];
  const companyname = ["TCS", "HCL", "Setu", "Tech-Mahindra", "Motherson"];

  const registertypes = [
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
    "On Behalf of Company/Business",
  ];
  // const registertypes = ['As an individual/Proprietor', 'As an individual/Proprietor', 'As an individual/Proprietor', 'As an individual/Proprietor', 'As an individual/Proprietor'];

  const taglines = ["NodeJs", "Java", "PHP", "Python", "Django"];
  const gstins = ["69876", "74678", "74987", "34536", "23487"];
  const registrationNumberCINs = ["65476", "98765", "34526", "98456", "34876"];
  const descriptions = ["Testing", "Dummy", "Testing", "Dummy", "Testing"];
  const github = [
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
  ];
  const companyURLs = [
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
    "https://www.linkedin.com/",
  ];
  const companyemails = [
    "abc@gmail.com",
    "def@gmail.com",
    "ghi@gmail.com",
    "jkl@gmail.com",
    "mno@gmail.com",
  ];

  // clientjobpost
  const projectlevels = ["Beginner", "Intermediate", "Advanced", "Expert", "Intermediate"];
  // const projectlevels = ["basic level", "expensive", "medium level", "expensive", "medium level"];
  const jobdurations = ["01 to 03 months", "03 to 06 months", "more than 6 months", "03 to 06 months", "more than 6 months"];
  const freelancertypes = ["Agency freelancer", "Independent freelancer", "New rising talent", "Independent freelancer", "New rising talent"];
  const englishlevels = ["Basic","Conversational","Professional", "Conversational","Professional"];
  const yearsofexperiencepreferreds = ["1", "2", "3", "2", "3"];
  const jobcategoriess = ["Information technology (IT) management","Information security","Technical support","Information security","Technical support"];
  const projectlocationtypes = ["onsite","hybrid","remote","hybrid","remote"];
  const jobtitles = ["React", "Node", "Java", "Node", "Java"];
  const workprices = ["200", "400", "600", "800", "999"];
  const jobdetails = ["Intern","Fresher","Experience","Fresher","Experience"];
  const joblocations = ["Ghaziabad", "Noida", "Delhi", "Noida", "Delhi"];

  const pickRandomName = () => {
    const randomIndex = Math.floor(
      Math.random() * names.length,
      lastNames.length,
      userNames.length,
      emails.length,
      passwords.length,
      countryNames.length,
      address.length,
      contact.length,
      companydetails.length,
      noofemployees.length,
      companyname.length,
      taglines.length,
      gstins.length,
      registertypes.length,
      descriptions.length,
      github.length,
      projectlevels.length,
      jobdurations.length,
      freelancertypes.length,
      englishlevels.length,
      yearsofexperiencepreferreds.length,
      jobcategoriess.length,
      projectlocationtypes.length,
      jobtitles.length,
      workprices.length,
      jobdetails.length,
      joblocations.length,
      registrationNumberCINs.lenght,
      companyURLs.length,
      companyemails.length
    );

    const randomName = names[randomIndex];
    setRandomName(randomName);

    const randomLastName = lastNames[randomIndex];
    setRandomLastName(randomLastName);

    const randomUserName = userNames[randomIndex];
    setRandomUserName(randomUserName);

    const randomEmail = emails;
    setRandomEmail(randomEmail);

    const randomPasswords = passwords[randomIndex];
    setPasswords(randomPasswords);

    const randomCountryNames = countryNames[randomIndex];
    setRandomCountryNames(randomCountryNames);

    const randomAddress = address[randomIndex];
    setAddress(randomAddress);

    const randomContact = contact[randomIndex];
    setContact(randomContact);

    const randomCompanyDetails = companydetails[randomIndex];
    setCompanyDetails(randomCompanyDetails);

    const randomNoOfEmployees = noofemployees[randomIndex];
    setNoOfEmployees(randomNoOfEmployees);

    const randomRegisterType = registertypes[randomIndex];
    setRegisterType(randomRegisterType);

    const randomCompanyName = companyname[randomIndex];
    setCompanyName(randomCompanyName);

    const randomTagline = taglines[randomIndex];
    setTagline(randomTagline);

    const randomGSTINs = gstins[randomIndex];
    setGSTINs(randomGSTINs);

    const randomDescription = descriptions[randomIndex];
    setDescription(randomDescription);

    const randomRegistrationNumberCIN = registrationNumberCINs[randomIndex];
    setRegistrationNumberCIN(randomRegistrationNumberCIN);

    const randomCompanyURL = companyURLs[randomIndex];
    setCompanyURL(randomCompanyURL);

    const randomCompanyEmail = companyemails[randomIndex];
    setCompanyEmail(randomCompanyEmail);

    const randomGitHub = github[randomIndex];
    setGitHub(randomGitHub);

    // clientjobpost
    const randomProjectLevel = projectlevels[randomIndex];
    setrandomProjectLevel(randomProjectLevel);

    const randomJobDuration = jobdurations[randomIndex];
    setrandomJobDuration(randomJobDuration);

    const randomFreelancerType = freelancertypes[randomIndex];
    setrandomFreelancerType(randomFreelancerType);

    const randomEnglishLevel = englishlevels[randomIndex];
    setrandomEnglishLevel(randomEnglishLevel);

    const randomYearsofExperiencePreferred =
      yearsofexperiencepreferreds[randomIndex];
    setrandomYearsofExperiencePreferred(randomYearsofExperiencePreferred);

    const randomJobCategories = jobcategoriess[randomIndex];
    setrandomJobCategories(randomJobCategories);

    const randomProjectLocationType = projectlocationtypes[randomIndex];
    setrandomProjectLocationType(randomProjectLocationType);

    const randomJobTitle = jobtitles[randomIndex];
    setrandomJobTitle(randomJobTitle);

    const randomWorkPrice = workprices[randomIndex];
    setrandomWorkPrice(randomWorkPrice);

    const randomJobDetails = jobdetails[randomIndex];
    setrandomJobDetails(randomJobDetails);

    const randomJobLocation = joblocations[randomIndex];
    setrandomJobLocation(randomJobLocation);
  };

  useEffect(() => {
    pickRandomName();
  }, []);

  const fnameInputChange = (e) => {
    setRandomName(e.target.value);
  };
  const lastInputChange = (e) => {
    setRandomLastName(e.target.value);
  };
  const userInputChange = (e) => {
    setRandomUserName(e.target.value);
  };
  const userEmailChange = (e) => {
    setRandomEmail(e.target.value);
  };
  const userPasswordsChange = (e) => {
    setPasswords(e.target.value);
  };
  const userCountryChange = (e) => {
    setRandomCountryNames(e.target.value);
  };
  const userAddressChange = (e) => {
    setAddress(e.target.value);
  };
  const userContactChange = (e) => {
    setContact(e.target.value);
  };
  const userCompanyDetailsChange = (e) => {
    setCompanyDetails(e.target.value);
  };

  const userNoOfEmployeesChange = (e) => {
    setNoOfEmployees(e.target.value);
  };

  const userRegisterTypeChange = (e) => {
    setRegisterType(e.target.value);
  };

  const userCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const userTaglineChange = (e) => {
    setTagline(e.target.value);
  };

  const userGSTINsChange = (e) => {
    setGSTINs(e.target.value);
  };

  const userDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const userRegistrationNumberCINChange = (e) => {
    setRegistrationNumberCIN(e.target.value);
  };

  const userCompanyURLChange = (e) => {
    setCompanyURL(e.target.value);
  };

  const userCompanyEmailChange = (e) => {
    setCompanyEmail(e.target.value);
  };

  const userGitHubChange = (e) => {
    setGitHub(e.target.value);
  };

  // clientjobpost
  const userProjectLevelChange = (e) => {
    setrandomProjectLevel(e.target.value);
  };
  const userJobDurationChange = (e) => {
    setrandomJobDuration(e.target.value);
  };
  const userFreelancerTypeChange = (e) => {
    setrandomFreelancerType(e.target.value);
  };
  const userEnglishLevelChange = (e) => {
    setrandomEnglishLevel(e.target.value);
  };
  const userYearsofExperiencePreferredChange = (e) => {
    setrandomYearsofExperiencePreferred(e.target.value);
  };
  const userJobCategoriesChange = (e) => {
    setrandomJobCategories(e.target.value);
  };

  const userProjectLocationTypeChange = (e) => {
    setrandomProjectLocationType(e.target.value);
  };

  const userrJobTitleChange = (e) => {
    setrandomJobTitle(e.target.value);
  };

  const userrWorkPriceChange = (e) => {
    setrandomWorkPrice(e.target.value);
  };

  const userJobDetailsChange = (e) => {
    setrandomJobDetails(e.target.value);
  };

  const userJobLocationChange = (e) => {
    setrandomJobLocation(e.target.value);
  };

  // date generate
  const [randomprojectStartDate, setrandomProjectStartDate] = useState("");
  const [randomprojectExpireDate, setrandomProjectExpireDate] = useState("");
  const [randomprojectDeadlineDate, setrandomProjectDeadlineDate] =
    useState("");

  //  date generate

  useEffect(() => {
    const currentDate = new Date();
    const twoDaysLater = new Date(currentDate);
    twoDaysLater.setDate(currentDate.getDate() + 2);
    const fourDaysLater = new Date(currentDate);
    fourDaysLater.setDate(currentDate.getDate() + 4);

    // Format the dates as YYYY-MM-DD
    const formattedCurrentDate = formatDate(currentDate);
    const formattedTwoDaysLater = formatDate(twoDaysLater);
    const formattedFourDaysLater = formatDate(fourDaysLater);

    setrandomProjectStartDate(formattedCurrentDate);
    setrandomProjectExpireDate(formattedTwoDaysLater);
    setrandomProjectDeadlineDate(formattedFourDaysLater);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const usersetProjectStartDate = (e) => {
    setrandomProjectStartDate(e.target.value);
  };
  const usersetProjectExpireDate = (e) => {
    setrandomProjectExpireDate(e.target.value);
  };
  const usersetProjectDeadlineDate = (e) => {
    setrandomProjectDeadlineDate(e.target.value);
  };

  const formattedStartDate = `${randomprojectStartDate}T00:00:00.000Z`;
  const formattedDeadlineDate = `${randomprojectDeadlineDate}T00:00:00.000Z`;
  const formattedExpireDate = `${randomprojectExpireDate}T00:00:00.000Z`;
  // close date

  // checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckbox1Change = () => {
    setIsChecked1(!isChecked1);
    // Automatically check "Profile fill up" when "Client Job Post" is checked
    if (!isChecked && !isChecked1) {
      setIsChecked(true);
    }
  };

  // dynamic data ke lie
  const [data, setData] = useState({
    fname: "",
    lname: "",
    password: "",
    uname: "",
    email: "",
    country: "",

    dateOfBirth: "",
    phone: "",
    description: "",
    companyDetailsDepartment: "",
    numberOfEmployees: "",
    companyName: "",
    GSTIN: "",
    tagLine: "",
    registeredOffice: "",
    companyContact: "",
    companyEmail: "",
    // Identity: null,
    // companyLogo: null,
    // profilePicture: null,

    facebook: "",
    github: "",
    instagram: "",
    linkedIn: "",
    othersLink: "",
    stackOverflow: "",
    youtube: "",

    // client job post
  title: "",
  experienceLevel: "",
  duration: "",
  freelancerType: "",
  languageProficiency: "",
  jobType: "",
  preferredExperience: "",
  startDate: "",
  deadline: "",
  expireDate: "",
  approxBudget: "",
  categories: "",
  language: "",
  details: "",
  requiredSkills: "",
  location: "",
  file: "",
  attachMent: "",
  projectMileStonePayment: "",
  numberOfOpening: "",
  });

  // dynamic data ke lie
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const [loading, setLoading] = useState(false); // State variable to track loading state
  const [color, setColor] = useState("#03a9f4");

  const [userSelectedFile, setUserSelectedFile] = useState("");
  // const [userCompanyLogoSelectedFile, setCompanyLogoSelectedFile] = useState("");
  // const [userProfilePictureSelectedFile, setProfilePictureSelectedFile] = useState("");
  const [broucherSelectedFile, setBroucherSelectedFile] = useState("");
  const [jobSelectedFile, setJobSelectedFile] = useState("");

  // async try catch
  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true when form is submitted

    try {
      const user_data1 = {
        accountType: "client",
        confirmPassword: randomPasswords,
        country: data.country || randomCountryNames,
        email: data.email || randomEmail,
        firstName: data.fname || randomName,
        lastName: data.lname || randomLastName,
        password: randomPasswords,
        rules: "on",
        userName: data.uname || randomUserName,
      };
      const res = await axiousPublic.post(`/auth/register`, user_data1);
      console.log("userprofile", res);
      console.log("userprofileIDD", res.data.data.user.userId);

      const user_data2 = {
        email: data.email || randomEmail,
      };
      console.log("user_data2logg", user_data2);

      // verify email
      const verificationResponse = await axiousPublic.patch(`/auth/verifyEmail/oneClick`, user_data2);
      const isValidEmail = verificationResponse.data.isValid;
      setVerificationResult(
        isValidEmail ? "Valid email address!" : "Invalid email address!"
      );

      // profile fill up
      if (isChecked === true) {
        const profileClientId = res.data.data.user.userId;
        const config11 = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + res.data.data.token,
          },
        };
        
          const formData = new FormData();
          formData.append("registerType", randomRegisterType);
          formData.append("dateOfBirth", "Jul 03, 2024");
          formData.append("phone", randomContact);
          formData.append("description", randomDescription);
          formData.append("companyDetailsDepartment", randomCompanyDetails);
          formData.append("numberOfEmployees", randomNoOfEmployees);
          formData.append("completedStep", 2);
          formData.append("companyName", randomCompanyName);
          formData.append("GSTIN", randomGSTINs);
          formData.append("tagLine", randomTagline);
          formData.append("registeredOffice", randomAddress);
          formData.append("companyContact", randomContact);
          formData.append("companyEmail", randomCompanyEmail);
          formData.append("Identity", userSelectedFile);
          formData.append("companyLogo", userSelectedFile);
          formData.append("profilePicture", userSelectedFile);

          const respstep1 = await axiousPublic.patch(`/clientProfile/${profileClientId}`, formData, config11);
          console.log(respstep1.data);

        const profile_client_step2 = {
          completedStep: 3,
          facebook: data.facebook || randomGitHub,
          github: data.github || randomGitHub,
          instagram: data.instagram || randomGitHub,
          linkedIn: data.linkedIn || randomGitHub,
          othersLink: data.othersLink || randomGitHub,
          stackOverflow: data.stackOverflow || randomGitHub,
          youtube: data.youtube || randomGitHub,
        };
        const respstep2 = await axiousPublic.patch(`/clientProfile/${profileClientId}`, profile_client_step2, config11);
        console.log(respstep2.data);

        const profile_client_step3 = new FormData();
        profile_client_step3.append("brochures", broucherSelectedFile);
        profile_client_step3.append("completedStep", 4);
        const respstep3 = await axiousPublic.post(`/clientProfile/brochures/${profileClientId}`, profile_client_step3, config11);
        console.log(respstep3.data);
      }

      // client job post
      if (isChecked1 === true) {
        
        const config11 = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + res.data.data.token,
            Id:res.data.data.user.userId,
          },
        };

        const formData = new FormData();
        formData.append("title", randomJobTitle);
        formData.append("experienceLevel", randomProjectLevel);
        formData.append("duration", randomJobDuration);
        formData.append("freelancerType", randomFreelancerType);
        formData.append("languageProficiency", randomEnglishLevel);
        formData.append("jobType", randomProjectLocationType);
        formData.append("preferredExperience", randomYearsofExperiencePreferred);
        formData.append("startDate", formattedStartDate);
        formData.append("deadline", formattedDeadlineDate);
        formData.append("expireDate", formattedExpireDate);
        formData.append("approxBudget", randomWorkPrice);
        formData.append("categories", randomJobCategories);
        formData.append("language", "English");
        formData.append("details", randomJobDetails);
        formData.append("requiredSkills", "html");
        formData.append("location", randomJobLocation);
        
        formData.append("file", jobSelectedFile);
        formData.append("attachMent", true);
        formData.append("projectMileStonePayment", true);
        formData.append("numberOfOpening", "6");
        
        const respstep3 = await axiousPublic.post(`/jobs`, formData, config11);
        console.log(respstep3.data); 

      }

      alert("Client Created Successfully !!!");
      window.location.reload();
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("Data not Submitted Try Again !!!"); // Show error toast
    } finally {
      setLoading(false); // Set loading state to false when response is received
    }
  };

  return (
    <div>
      <Header />

      <div className="container">
      <Toaster position="top-center" reverseOrder={false} />
        {loading ? (
          <div className="loader-wrapper">
            <Loader
              type="spinner-circle"
              bgColor={color}
              color={color}
              title={"Processing Your Request"}
              size={100}
            />
          </div>
        ) : (
        <form onSubmit={formSubmit}>
          <div className="row jumbotron box8">
            <div className="col-sm-12 mx-t3 mb-4">
              <h2 className="text-center text-info">
                Client with Company Register
              </h2>
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="name-f">First Name</label>
              <input
                type="text"
                className="form-control"
                name="fname"
                id="namef"
                placeholder="Enter your first name."
                required=""
                // value={randomName} onChange={handleInputChange}
                value={data.fname || randomName}
                onChange={(event) => {
                  inputEvent(event);
                  fnameInputChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="name-l">Last name</label>
              <input
                type="text"
                className="form-control"
                name="lname"
                id="namel"
                placeholder="Enter your last name."
                required=""
                // value={randomLastName} onChange={lastInputChange}
                value={data.lname || randomLastName}
                onChange={(event) => {
                  inputEvent(event);
                  lastInputChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="pass">Password</label>
              <input
                type="Password"
                name="password"
                className="form-control"
                id="pass"
                placeholder="Enter your password."
                required=""
                // value='randomPasswords'
                value={data.password || randomPasswords}
                // onChange={inputEvent}
                onChange={(event) => {
                  inputEvent(event);
                  userPasswordsChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="pass">Confirm Password</label>
              <input
                type="text"
                name="confirmPassword"
                className="form-control"
                id="confirmPassword"
                placeholder="Enter your password."
                required=""
                value={randomPasswords}
                // value={data.password || randomPasswords}
                // onChange={inputEvent}
                onChange={(event) => {
                  inputEvent(event);
                  // userPasswordsChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="name-un">User Name</label>
              <input
                type="text"
                className="form-control"
                name="uname"
                id="name-un"
                placeholder="Enter your user name."
                required=""
                // value={randomUserName} onChange={userInputChange}
                value={data.uname || randomUserName}
                // onChange={inputEvent}
                onChange={(event) => {
                  inputEvent(event);
                  userInputChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Enter your email."
                required=""
                // value={randomEmail} onChange={userEmailChange}
                value={data.email || randomEmail}
                // onChange={inputEvent}
                onChange={(event) => {
                  inputEvent(event);
                  userEmailChange(event);
                }}
              />
            </div>
            <div className="col-sm-6 form-group label1">
              <label htmlFor="Country">Country</label>
              <input
                type="test"
                className="form-control"
                name="country"
                id="country"
                placeholder=""
                required=""
                // value={randomCountryNames} onChange={userCountryChange}
                value={data.country || randomCountryNames}
                // onChange={inputEvent}
                onChange={(event) => {
                  inputEvent(event);
                  userCountryChange(event);
                }}
              />
            </div>

            {/* <div className="col-sm-12 label1">
        <input
          type="checkbox"
          className="form-check d-inline"
          id="chb"
          required=""
        />
        <label htmlFor="chb" className="form-check-label">
          &nbsp;I accept all terms and conditions.
        </label>
      </div>&nbsp; */}
            &nbsp;
            <div className="col-sm-12 label1">
              <input
                type="checkbox"
                className="form-check d-inline"
                id="chb1"
                required=""
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="chb1" className="form-check-label">
                &nbsp;Profile fill up
              </label>
            </div>
            &nbsp;
            {/* client job post  check box*/}
            <div className="col-sm-12 label1">
              <input
                type="checkbox"
                className="form-check d-inline"
                id="chb2"
                required=""
                checked={isChecked1}
                onChange={handleCheckbox1Change}
              />
              <label htmlFor="chb2" className="form-check-label">
                &nbsp;Client Job Post
              </label>
            </div>
            <br />
            <br />
            {/* close client job post check box*/}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="registerType">RegisterType</label>
                <input
                  type="text"
                  className="form-control"
                  name="registerType"
                  id="registerType"
                  placeholder=""
                  required=""
                  value={data.registerType || randomRegisterType}
                  // onChange={(event) => {
                  //   inputEvent(event);
                  //   userRegisterTypeChange(event);
                  // }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="dateOfBirth">Date of birth</label>
                <input
                  type="date"
                  className="form-control"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  placeholder=""
                  required=""
                  value={data.dateOfBirth} //|| randomdateOfBirth}
                  onChange={(event) => {
                    inputEvent(event);
                    // userdateOfBirthChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="contact">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="contact"
                  id="contact"
                  placeholder=""
                  required=""
                  // value={randomContact} onChange={userContactChange}
                  value={data.contact || randomContact}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userContactChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  id="description"
                  placeholder=""
                  required=""
                  value={data.description || randomDescription}
                  onChange={(event) => {
                    inputEvent(event);
                    userDescriptionChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="companyDetailsDepartment">
                  Company Details
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="companyDetailsDepartment"
                  id="companyDetailsDepartment"
                  placeholder="Enter your Title"
                  required=""
                  // value={randomCompanyDetails} onChange={userCompanyDetailsChange}
                  value={data.companyDetailsDepartment || randomCompanyDetails}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userCompanyDetailsChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="numberOfEmployees">No. Of Employees</label>
                <input
                  type="text"
                  className="form-control"
                  name="numberOfEmployees"
                  id="numberOfEmployees"
                  placeholder=""
                  required=""
                  // value={randomNoOfEmployees} onChange={userNoOfEmployeesChange}
                  value={data.numberOfEmployees || randomNoOfEmployees}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userNoOfEmployeesChange(event);
                  }}
                />
              </div>
            )}

          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="companyName">Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyName"
                  id="companyName"
                  placeholder=""
                  required=""
                  value={data.companyName || randomCompanyName}
                  onChange={(event) => {
                    inputEvent(event);
                    userCompanyNameChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="GSTIN">GSTIN</label>
                <input
                  type="text"
                  className="form-control"
                  name="GSTIN"
                  id="GSTIN"
                  placeholder=""
                  required=""
                  value={data.GSTIN || randomGSTINs}
                  onChange={(event) => {
                    inputEvent(event);
                    userGSTINsChange(event);
                  }}
                />
              </div>
            )}

          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="tagLine">Tag Line</label>
                <input
                  type="text"
                  className="form-control"
                  name="tagLine"
                  id="tagLine"
                  placeholder=""
                  required=""
                  value={data.tagLine || randomTagline} //userTaglineChange
                  onChange={(event) => {
                    inputEvent(event);
                    userTaglineChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="registeredOffice">
                  Address of Registered Office
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="registeredOffice"
                  id="registeredOffice"
                  placeholder=""
                  required=""
                  value={data.registeredOffice || randomAddress}
                  onChange={(event) => {
                    inputEvent(event);
                    userAddressChange(event);
                  }}
                />
              </div>
            )}

          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="companyContact">Company Contact</label>
                <input
                  type="text"
                  className="form-control"
                  name="companyContact"
                  id="companyContact"
                  placeholder=""
                  required=""
                  // value={randomContact} onChange={userContactChange}
                  value={data.companyContact || randomContact}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userContactChange(event);
                  }}
                />
              </div>
            )}

            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="companyEmail">Company Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="companyEmail"
                  id="companyEmail"
                  placeholder=""
                  required=""
                  //   value={data.companyEmail}
                  //   onChange={inputEvent}
                  value={data.companyEmail || randomCompanyEmail}
                  onChange={(event) => {
                    inputEvent(event);
                    userCompanyEmailChange(event);
                  }}
                />
              </div>
            )}

          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="Identity">Identity</label>
                <input
               type="file"
               id="Identity"
               name="Identity"
               accept="image/*"
               onChange={(e) => setUserSelectedFile(e.target.files[0])}
               className="form-control"
               required="true"
                />
              </div>
            )}


          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="companyLogo">CompanyLogo</label>
                <input
                type="file"
                id="companyLogo"
                name="companyLogo"
                accept="image/*"
                className="form-control"
                onChange={(e) => setUserSelectedFile(e.target.files[0])}
                required="true"
                />
              </div>
            )}
          {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="profilePicture">Profile Picture</label>
                <input
                type="file"
                id="profilePicture"
                name="profilePicture"
                accept="image/*"
                onChange={(e) => setUserSelectedFile(e.target.files[0])}
                className="form-control"
                required="true"
                />
              </div>
            )}            

            {/* social link */}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="facebook">Facebook</label>
                <input
                  type="text"
                  className="form-control"
                  name="facebook"
                  id="facebook"
                  placeholder=""
                  required=""
                  // value={data.facebook}
                  // onChange={inputEvent}

                  value={data.facebook || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="github">GitHub</label>
                <input
                  type="text"
                  className="form-control"
                  name="github"
                  id="github"
                  placeholder=""
                  required=""
                  // value={data.github}
                  // onChange={inputEvent}

                  value={data.github || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="instagram">Instagram</label>
                <input
                  type="text"
                  className="form-control"
                  name="instagram"
                  id="instagram"
                  placeholder=""
                  required=""
                  // value={data.instagram}
                  // onChange={inputEvent}

                  value={data.instagram || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="linkedIn">LinkedIn</label>
                <input
                  type="text"
                  className="form-control"
                  name="linkedIn"
                  id="linkedIn"
                  placeholder=""
                  required=""
                  // value={data.linkedIn}
                  // onChange={inputEvent}

                  value={data.linkedIn || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="othersLink">OthersLink</label>
                <input
                  type="text"
                  className="form-control"
                  name="othersLink"
                  id="othersLink"
                  placeholder=""
                  required=""
                  // value={data.othersLink}
                  // onChange={inputEvent}

                  value={data.othersLink || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="stackOverflow">StackOverflow</label>
                <input
                  type="text"
                  className="form-control"
                  name="stackOverflow"
                  id="stackOverflow"
                  placeholder=""
                  required=""
                  // value={data.stackOverflow}
                  // onChange={inputEvent}

                  value={data.stackOverflow || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="youtube">YouTube</label>
                <input
                  type="text"
                  className="form-control"
                  name="youtube"
                  id="youtube"
                  placeholder=""
                  required=""
                  // value={data.youtube}
                  // onChange={inputEvent}

                  value={data.youtube || randomGitHub}
                  // onChange={inputEvent}
                  onChange={(event) => {
                    inputEvent(event);
                    userGitHubChange(event);
                  }}
                />
              </div>
            )}
            {/*close social link */}

            {/* Brochures */}
            {isChecked && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="brochures">Brochures</label>
                <input
                  type="file"
                  id="brochures"
                  name="brochures"
                  accept="image/*"
                  className="form-control"
                  placeholder=""
                  required="true"
                  onChange={(e) => setBroucherSelectedFile(e.target.files[0])}

                />
              </div>
            )}
            {/* close Brochures */}

            {/* client job post*/}
            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="title">Job Title</label>
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="form-control"
                  placeholder=""
                  value={data.title || randomJobTitle}
                  onChange={(event) => {
                    inputEvent(event);
                    userrJobTitleChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="experienceLevel">Project Level</label>
                <input
                  type="text"
                  name="experienceLevel"
                  id="experienceLevel"
                  className="form-control"
                  placeholder=""
                  value={data.experienceLevel|| randomProjectLevel} // || randomJobTitle}
                  onChange={(event) => {
                    inputEvent(event);
                    // userrJobTitleChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="duration">Job Duration</label>
                <input
                  type="text"
                  name="duration"
                  id="duration"
                  className="form-control"
                  placeholder=""
                  value={data.duration || randomJobDuration}
                  onChange={(event) => {
                    inputEvent(event);
                    userJobDurationChange(event);
                  }}
                />
              </div>
            )}
            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="freelancerType">Freelancer Type</label>
                <input
                  type="text"
                  name="freelancerType"
                  id="freelancerType"
                  className="form-control"
                  placeholder=""
                  value={data.freelancerType || randomFreelancerType}
                  onChange={(event) => {
                    inputEvent(event);
                    userFreelancerTypeChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="languageProficiency">English Level</label>
                <input
                  type="text"
                  name="languageProficiency"
                  id="languageProficiency"
                  className="form-control"
                  placeholder=""
                  value={randomEnglishLevel}
                  onChange={(event) => {
                    inputEvent(event);
                  }}
                />
              </div>
            )}
            
            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="jobType">Project Location Type</label>
                <input
                  type="text"
                  name="jobType"
                  id="jobType"
                  className="form-control"
                  placeholder=""
                  value={data.jobType || randomProjectLocationType}
                  onChange={(event) => {
                    inputEvent(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="preferredExperience">Years of Experience Preferred</label>
                <input
                  type="text"
                  name="preferredExperience"
                  id="preferredExperience"
                  className="form-control"
                  placeholder=""
                  value={data.preferredExperience || randomYearsofExperiencePreferred}
                  onChange={(event) => {
                    inputEvent(event);
                    userYearsofExperiencePreferredChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="startDate">Project Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  className="form-control"
                  placeholder=""
                  value={data.startDate || randomprojectStartDate}
                  onChange={(event) => {
                    inputEvent(event);
                    usersetProjectStartDate(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="deadline">Project Deadline Date</label>
                <input
                  type="date"
                  name="deadline"
                  id="deadline"
                  className="form-control"
                  placeholder=""
                  value={data.deadline || randomprojectDeadlineDate}
                  onChange={(event) => {
                    inputEvent(event);
                    usersetProjectDeadlineDate(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="expireDate">Project Expire Date</label>
                <input
                  type="date"
                  name="expireDate"
                  id="expireDate"
                  className="form-control"
                  placeholder=""
                  value={data.expireDate || randomprojectExpireDate}
                  onChange={(event) => {
                    inputEvent(event);
                    usersetProjectExpireDate(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="approxBudget">Work Price ($)</label>
                <input
                  type="text"
                  name="approxBudget"
                  id="approxBudget"
                  className="form-control"
                  placeholder=""
                  value={data.approxBudget || randomWorkPrice}
                  onChange={(event) => {
                    inputEvent(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="categories">Job Categories</label>
                <input
                  type="text"
                  name="categories"
                  id="categories"
                  className="form-control"
                  placeholder=""
                  value={data.categories || randomJobCategories}
                  onChange={(event) => {
                    inputEvent(event);
                    userJobCategoriesChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="language">Language</label>
                <input
                  type="text"
                  name="language"
                  id="language"
                  className="form-control"
                  placeholder=""
                  value={data.language || "English"}
                  onChange={(event) => {
                    inputEvent(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="details">Job Details</label>
                <input
                  type="text"
                  name="details"
                  id="details"
                  className="form-control"
                  placeholder=""
                  value={data.details || randomJobDetails}
                  onChange={(event) => {
                    inputEvent(event);
                    userJobDetailsChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="requiredSkills">Required Skills</label>
                <input
                  type="text"
                  name="requiredSkills"
                  id="requiredSkills"
                  className="form-control"
                  placeholder=""
                  value={data.requiredSkills || "html"}
                  onChange={(event) => {
                    inputEvent(event);
                    // fnameInputChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  name="location"
                  id="location"
                  className="form-control"
                  placeholder=""
                  value={data.location || randomJobLocation}
                  onChange={(event) => {
                    inputEvent(event);
                    userJobLocationChange(event);
                  }}
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="file"> Upload Project File</label>
                <input
                 type="file"
                 id=" file"
                 name="file"
                 accept="image/*"
                 className="form-control"
                 placeholder=""
                 onChange={(e) => setJobSelectedFile(e.target.files[0])}
                 required="true"
                />
              </div>
            )}

            {isChecked1 && (
              <div className="col-sm-6 form-group label1">
                <label htmlFor="numberOfOpening">numberOfOpening</label>
                <input
                  type="text"
                  name="numberOfOpening"
                  id="numberOfOpening"
                  className="form-control"
                  placeholder=""
                  value={6}
                />
              </div>
            )}
            {/* close client job post*/}

            <div className="col-sm-12 form-group mb-0">
              <button type="submit" className="btn btn-primary float-right">
                Submit
              </button>
            </div>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default ClientWithCompany;
