//const axios = require('axios')
import axios from "axios";

//const ES_HOST = "https://localhost:9200"; // Elasticsearch host
const INDEX_NAME = "genesis"; // Name of the index to store logs
const ES_HOST = 'http://logging.airepro.in/api/log' //backend logger system url

export  function logError(message, error) {
  const logData = {
    "@timestamp": new Date().toISOString(),
    level: "error",
    message: message,
    error: error.stack, // Assuming error is an instance of Error
    // Add more fields as needed
  };

  sendLog(logData);
}

export  function logInfo (message, data) {
  
  const logData = {
    "@timestamp": new Date().toISOString(),
    message: message,
    ...data
  };
  sendLog(logData);
}

async function sendLog(logData) {
  try {
    
    
    //const response = await axios.post(`${ES_HOST}/${INDEX_NAME}/_doc`, logData);
    const response = await axios.post(`${ES_HOST}/${INDEX_NAME}`, logData);
    console.log("Log sent successfully:", response.data);
  } catch (error) {
    console.error("Error sending log to Elasticsearch:", error);
  }
}

// Example usage
try {
  // Some operation that might throw an error
  throw new Error("Something went wrong");
} catch (error) {
  logError("An error occurred", error);
}
